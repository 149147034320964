import {ConfigProvider, Menu} from "antd";
import {MainThemes} from "../../themes/MainThemes";
import styles from "./ScienceLayout.module.css"
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {NavLink, useLocation} from "react-router-dom";

export const ScienceLayout = ({children, title}) =>{
    const location = useLocation();
    const {lang} = useSelector(state => state.lang);
    const getSelectedKey = (pathname) => {
        return pathname;
    };
    const onOpenChange  = (pathname) => {
        if (pathname.startsWith("/science/competitions")) return ["/science/competitions", pathname];
        return [pathname];
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const items = [
        {
            key: "/science",
            label: <NavLink to="/science">{lang === 'ru' ? "Главная" : "Main Page"}</NavLink>,
        },
        {
            key: "/science/map",
            label: <NavLink to="/science/map">{lang === 'ru' ? "Интерактивная карта с университетами и программами обмена" : "Interactive map with universities and exchange programs"}</NavLink>,
        },
        {
            key: "/science/competitions",
            label: <NavLink to="/science/competitions">{lang === 'ru' ? "Конкурсы и гранты" : "Competitions and grants"}</NavLink>,
            children: [
                {
                    key: "/science/competitions/china",
                    label: <NavLink to="/science/competitions/china">{lang === 'ru' ? "Конкурсы и гранты Китая" : "Competitions and grants in China"}</NavLink>,
                },
                {
                    key: "/science/competitions/brazil",
                    label: <NavLink to="/science/competitions/brazil">{lang === 'ru' ? "Конкурсы и гранты Бразилии" : "Competitions and grants in Brazil"}</NavLink>,
                },
                {
                    key: "/science/competitions/uae",
                    label: <NavLink to="/science/competitions/uae">{lang === 'ru' ? "Конкурсы и гранты ОАЭ" : "Competitions and grants in UAE"}</NavLink>,
                },
                {
                    key: "/science/competitions/argentina",
                    label: <NavLink to="/science/competitions/argentina">{lang === 'ru' ? "Конкурсы и гранты Аргентины" : "Competitions and grants in Argentina"}</NavLink>,
                },
            ],
        },
        {
            key: "/science/success_stories",
            label: <NavLink to="/science/success_stories">{lang === 'ru' ? "Истории успеха" : "Success Stories"}</NavLink>,
        }

    ]

    return (
        <ConfigProvider theme={MainThemes.forum}>
            <div className={styles.science}>
                <h1>
                    {title}
                </h1>
                <div className={styles.info}>
                    <div className={styles.left}>
                        {children}
                    </div>
                    <div className={styles.right}>
                        <Menu mode="inline" style={{justifyContent: "left"}}  selectedKeys={[getSelectedKey(location.pathname)]} items={items}/>
                    </div>
                </div>

            </div>
        </ConfigProvider>
    );
}