import React from 'react';
import {Table} from "antd";
import {useSelector} from "react-redux";
import {ScienceLayout} from "../../../Layouts/Science/ScienceLayout";

const ScienceTable = () => {
    const {lang} = useSelector(state => state.lang);

    const columns = [
        {
            title: "Наименование показателя",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Выборка",
            dataIndex: "sample",
            key: "sample",
        },
        {
            title: "Значение по РФ",
            dataIndex: "value",
            key: "value",
        }
    ]
    const enColumns = [
        {
            title: "Name of the indicator",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Sample",
            dataIndex: "sample",
            key: "sample",
        },
        {
            title: "Value for RF",
            dataIndex: "value",
            key: "value",
        }
    ]

    const data = [
        {
            name: '1.1. Общая численность студентов, обучающихся по образовательным программам среднего профессионального образования – программам подготовки квалифицированных рабочих, служащих и программам подготовки специалистов среднего звена, чел.',
            sample: '',
            value: 3551189
        }
    ]

    return (
        <ScienceLayout title={lang === 'ru' ? 'Наука' : 'Science'} lang={lang}>
            <Table columns={lang === 'ru' ? columns : enColumns} dataSource={data} pagination={false}/>
        </ScienceLayout>
    )
};

export default ScienceTable;
