import React from 'react';
import {useSelector} from "react-redux";
import {ScienceLayout} from "../../../../Layouts/Science/ScienceLayout";

const ScienceCompetitionsArgentina = () => {
    const {lang} = useSelector(state => state.lang);

    return (
        <ScienceLayout title={lang === 'ru' ? 'Конкурсы и гранты Аргентины' : 'Competitions and grants in Argentina'} lang={lang}>

        </ScienceLayout>
    )
};

export default ScienceCompetitionsArgentina;
