import React from 'react';
import {useSelector} from "react-redux";
import {ScienceLayout} from "../../../../Layouts/Science/ScienceLayout";

const ScienceCompetitionsBrazil = () => {
    const {lang} = useSelector(state => state.lang);

    return (
        <ScienceLayout title={lang === 'ru' ? 'Конкурсы и гранты Бразилии' : 'Competitions and grants in Brazil'} lang={lang}>

        </ScienceLayout>
    )
};

export default ScienceCompetitionsBrazil;
