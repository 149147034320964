import React from 'react';
import {Card, Col, Row} from "antd";
import {useSelector} from "react-redux";
import {ScienceLayout} from "../../../Layouts/Science/ScienceLayout";
import s from "../Science.module.css"

const ScienceSuccessStories = () => {
    const {lang} = useSelector(state => state.lang);


    return (
        <ScienceLayout title={lang === 'ru' ? 'Истории успеха' : 'Success Stories'} lang={lang}>
            <Row gutter={[32, 32]}>
                {Array.from({length: 8}).map((_, index) => (
                    <Col xs={24} sm={24} md={24} lg={24} xl={8} key={index}>
                        <AuthorCard/>
                    </Col>))}
            </Row>
        </ScienceLayout>
    )
};

const AuthorCard = () => {
    return (
        <div className={s.author}>
            <img src="https://ptzgovorit.ru/sites/default/files/original_nodes/dd_17.jpg"/>
            <div className={s.info}>
                <div>
                    ОАЭ
                </div>
                <div>
                    Описание на несколько строчек, на которых расписывается все самое важное. Возможно, нужно поставить
                    ограничение в 4-5 строчек
                </div>
                <div>
                    <a href="#">Скачать историю</a>
                </div>
            </div>

        </div>
    )
}

export default ScienceSuccessStories;
