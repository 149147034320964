import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {ScienceLayout} from "../../Layouts/Science/ScienceLayout";
import {BlockTitleWithUrl} from "../News/SingleNewsPage";
import {NewsDisplay} from "../../Components/Main/NewsBlock";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import {EventsDisplay} from "../../Components/Main/EventsBlock";

const Science = () => {
    const {lang} = useSelector(state => state.lang);
    const isSmall = useMediaQuery({ query: "(max-width: 768px)" });
    const [respData, setData] = useState("");
    const [respData2, setData2] = useState("");
    const [load, setLoad] = useState(false);
    const [load2, setLoad2] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await axios
                .get("https://bricsyoung.com/api/news?category=наука")
                .then((res) => {
                    setData(res.data);
                })
                .catch((e) => {});
        };
        fetchData().finally(() => setLoad(true));
    }, []);

    const response = Object.values(respData)
        .map(function (item) {
            return {
                body: item.body,
                bodyen: item.bodyen,
                date: item.date,
                description: item.description,
                descriptionen: item.descriptionen,
                header: item.header,
                headeren: item.headeren,
                id: item.id,
                category: item.category,
                img: "https://bricsyoung.com" + item.img,
            };
        })
        .filter((el) => {
            let today = new Date();
            let second = new Date(el?.date);
            return second.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0);
        })
        .slice(0, 4);

    useEffect(() => {
        const fetchData2 = async () => {
            await axios
                .get("https://bricsyoung.com/api/science")
                .then((res) => {
                    setData2(res.data);
                })
                .catch((e) => {});
        };
        fetchData2().finally(() => setLoad2(true));
    }, []);

    const response2 = Object.values(respData2)
        .map(function (item) {
            return {
                body: item.body,
                bodyen: item.bodyen,
                date: item.date || "",
                place: "",
                placeen: "",
                description: item.description,
                descriptionen: item.descriptionen,
                header: item.header,
                headeren: item.headeren,
                id: item.id || "",
                category: item.category,
                img: "https://bricsyoung.com" + item.img,
            };
        })
        .slice(0, 4);

    return (
        <ScienceLayout title={lang==='ru' ? "Наука" : "Science"}>
            <>
                <div>
                    <BlockTitleWithUrl
                        size={isSmall ? "16px" : "28px"}
                        ruTitle="Новости науки"
                        enTitle="Science News"
                        url="/news"
                    />
                    <NewsDisplay response={response} load={load} lang={lang}/>
                </div>
                <p></p>
                <div>
                    <BlockTitleWithUrl
                        size={isSmall ? "16px" : "28px"}
                        ruTitle="Анонсы"
                        enTitle="Announcements"
                        url="/announcements"
                    />
                    <EventsDisplay response={response2} load={load2} lang={lang}/>
                </div>
                <p></p>
                <div style={{fontSize: "48px", marginTop: "50px"}}>
                    Будущий блок для информации
                </div>
            </>
        </ScienceLayout>
    )
};

export default Science;
